@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: page-knowledge-item-versions;

.@{base-cls} {

  .cmp-drawer-body {
    padding-right: 0 !important;
    padding-top: 24px !important;
  }

  &-body {
    display: flex;
    gap: 24px;
    align-items: flex-start;
    overflow: hidden;
    

    .cmp-list {
      min-width: 280px;
      max-height: calc(100vh - 92px);
      overflow: auto;
      padding-right: 12px;

      .cmp-list-item {
        margin-right: 0 !important;
      }
    }

    .@{base-cls}-diff {
      max-height: calc(100vh - 92px);
      overflow: auto;
      width: 100%;
      margin: 0;
      padding-right: 12px;
    }
  }
}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;