@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-drawer;

.@{base-cls} {
  inset: 0;
  pointer-events: none;
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: @layer-210;

  &.@{base-cls}-tablet {
    .@{base-cls}-body {
        padding: 36px 24px 20px !important;
    }
  }

  &.@{base-cls}-mobile {
    .@{base-cls}-body {
        padding: 36px 24px 20px !important;
    }
  }

  .@{base-cls}-mask {
    pointer-events: all;
  }


  .@{base-cls}-title {
    .mixin-typography-text(title);
    padding-top: 20px;
    padding-inline: 24px;
  }

  &.@{base-cls}-small {
    .@{base-cls}-content-wrapper {
      width: calc(100% - 16px) !important;
      max-width: 420px !important;
    }
  }

  &.@{base-cls}-middle {
    .@{base-cls}-content-wrapper {
      min-width: 70%;
      max-width: 70%;
    }
  }

  &.@{base-cls}-large {
    .@{base-cls}-content-wrapper {
      min-width: calc(100% - 90px);
      max-width: calc(100% - 90px);
    }
  }

  &.@{base-cls}-open {
    width: auto !important;
    z-index: @layer-210 !important;
    display: block;
    visibility: visible;
  }

  &.@{base-cls}-right {
    .@{base-cls}-content-wrapper {
      margin-left: 16px;
      border-left: 1px solid @color-secondary-80;
      left: auto;

      .@{base-cls}-close {
        top: 16px !important;
        left: -16px !important;
      }
    }
  }

  &.@{base-cls}-left {
    .@{base-cls}-content-wrapper {
      margin-right: 16px;
      border-right: 1px solid @color-secondary-80;
      right: auto;

      .@{base-cls}-close {
        top: 16px !important;
        right: -16px !important;
      }
    }
  }

  &.@{base-cls}-top {
    .@{base-cls}-content-wrapper {
      border-bottom: 1px solid @color-secondary-80;
      bottom: auto;

      .@{base-cls}-close {
        top: 16px !important;
        left: -16px !important;
      }
    }
  }

  &.@{base-cls}-bottom {
    .@{base-cls}-content-wrapper {
      border-top: 1px solid @color-secondary-80;
      top: auto;
    }
  }

  .@{base-cls}-mask {
    background: rgba(50, 50, 50, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .@{base-cls}-content-wrapper {
    background-color: @color-background-50;
    width: auto !important;
    pointer-events: all;
    transition: all .3s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;

    .@{base-cls}-content {
      height: 100%;

      .@{base-cls}-wrapper-body {
        height: 100%;
        overflow: auto;

        .@{base-cls}-body {
          padding: 36px 24px 20px;
        }
      }
    }



    .@{base-cls}-close {
      background-color: @color-primary-50;
      border-radius: 50%;
      position: absolute;
      line-height: 0;
      padding: 4px;
      border: none;
      margin: 0;
      cursor: pointer;

      .icon {
        font-size: 24px;
        color: @color-background-50;
      }
    }
  }

}
@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;