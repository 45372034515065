@color-primary-10: #E7EDF8;
@color-primary-30: #9FB7E2;
@color-primary-50: #5074B4;

@color-text-20: #7D7D7D;
@color-text-50: #414141;
@color-text-100: #323232;

@color-background-50: #FFFFFF;

@color-secondary-50: #FEFDFC;
@color-secondary-60: #fafafa;
@color-secondary-70: #ECECEC;
@color-secondary-80: #E8E8E8;
@color-secondary-95: #DDDDDD;
@color-secondary-100: #b8b8b8;

@color-warning-10: lighten(#FFEBB6, 8%);
@color-warning-50: #FFEBB6;
@color-warning-100: #F5B912;

@color-error-10: lighten(#FFB3B3, 8%);
@color-error-50: #FFB3B3;
@color-error-100: #FF6767;

@color-success-10: lighten(#AEF2C1, 8%);
@color-success-50: #AEF2C1;
@color-success-100: #5DC288;

@box-shadow-50: 4px 4px 8px 0px #00000012;

@size-font-bodysmall: 12px;
@size-lineheight-bodysmall: 16px;
@font-family-bodysmall: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
@font-weight-bodysmall: 400;
@font-spacing-bodysmall: normal;

@size-font-bodymedium: 14px;
@size-lineheight-bodymedium: 20px;
@font-family-bodymedium: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
@font-weight-bodymedium: 500;
@font-spacing-bodymedium: normal;

@size-font-bodylarge: 16px;
@size-lineheight-bodylarge: 24px;
@font-family-bodylarge: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
@font-weight-bodylarge: 500;
@font-spacing-bodylarge: normal;

@size-font-title: 20px;
@size-lineheight-title: 28px;
@font-family-title: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
@font-weight-title: 500;
@font-spacing-title: normal;

@header-height: 80px;

@layer-100: 1;
@layer-200: 10;
@layer-210: 11;
@layer-300: 100;
@layer-310: 110;
@layer-400: 1000;