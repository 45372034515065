@import "../../helpers/Variables.less";
@import "../../helpers/Mixins.less";

@base-cls: cmp-text-editor;

.@{base-cls} {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;

  *[data-element-type="system"] {
    color: red;
  }

  &:not(.@{base-cls}-full-screen) {
    border: 1px solid @color-secondary-80;
  }

  &.@{base-cls}-full-screen {
    position: fixed;
    z-index: @layer-200;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .@{base-cls}-editor {
    overflow: auto;
    flex: 1;
  }
}


.cmp-form-item-has-error{
  .@{base-cls} {
    border: 1px solid @color-error-100 ;
  }
}

.tox-notifications-container {
  display: none !important;
  height: 0 !important;
  overflow: hidden !important;
}

@primary-color: #5074B4;@success-color: #AEF2C1;@warning-color: #FFEBB6;@error-color: #FFB3B3;@text-color: #323232;@text-color-secondary: #7D7D7D;@text-color-inverse: #FFFFFF;@border-radius-base: 4px;